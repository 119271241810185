import { ACCOUNTS_BALANCE } from "../endpoints";
import api from "../api";

export default {
    index(account) {

        if (account === null) return;

        const url = ACCOUNTS_BALANCE.replace(":account", account);

        return api.get(url);
    }
}