<template>
  <form
    action=""
    method="POST"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
    enctype="multipart/form-data"
    class="max-w-3xl mx-auto"
  >
    <v-card class="mb-2">
      <v-card-body>
        <v-label>Comision </v-label>
        <select-fee v-model="form.fee_id" selectId="fee_id" class="w-full" />
        <v-error :error="form.errors.get('fee_id')"></v-error>
      </v-card-body>
      <v-card-footer class="text-right">
        <v-button :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button>
      </v-card-footer>
    </v-card>
  </form>
</template>
<script>
import vendorsFeesApi from "@/api/resources/vendorsFeesApi";
import Form from "@/core/Form";
import VButton from "@/components/ui/VButton.vue";
import VCard from "@/components/ui/VCard.vue";
import VLabel from "@/components/ui/VLabel.vue";
import VError from "@/components/ui/VError.vue";
import VCardBody from "@/components/ui/VCardBody.vue";
import VCardFooter from "@/components/ui/VCardFooter.vue";
import VButtonIcon from "@/components/ui/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";
import SelectFee from "./shared/SelectFee.vue";

export default {
  components: {
    VCard,
    VLabel,
    VButton,
    VError,
    VCardBody,
    VCardFooter,
    VButtonIcon,
    Loader,
    SelectFee,
  },

  props: {
    vendorId: { type: String },
  },

  emits: ["fee:created"],

  data() {
    return {
      form: new Form({
        fee_id: "",
      }),
      loading: false,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.form
        .submitService(vendorsFeesApi, "store", {
          vendor: this.vendorId,
        })
        .then((response) => {
          this.$emit("fee:created", response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
