import { LEDGER_ENTRIES } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        let url = new URL(LEDGER_ENTRIES);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },
}