<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Data</v-table-th>
        <v-table-th>Descriere</v-table-th>
        <v-table-th>Valoare</v-table-th>
        <v-table-th>Cont</v-table-th>
        <v-table-th>Cont sintetic</v-table-th>
        <v-table-th>Credit</v-table-th>
        <v-table-th>Debit</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ row.transaction.transaction_date }}
        </v-table-td>
        <v-table-td>
          {{ row.transaction.description }}
        </v-table-td>
        <v-table-td>
          {{ row.transaction.amount }}
        </v-table-td>
        <v-table-td>
          {{ row.account.name }}
          <p>Cod oficial: {{ row.account.official_code }}</p>
        </v-table-td>

        <v-table-td>
          {{ row.analytical_account?.name }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.credit) }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.debit) }}
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
import { priceFormatter } from "@/helpers";
export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
  },

  props: {
    rows: {
      type: Array,
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
