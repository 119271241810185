<template>
  <app-layout>
    <template v-slot:header> Intrari registru </template>
    <page-section>
      <v-card>
        <v-card-header>
          <v-card-title> Balanta casa: {{ houseBalance }} </v-card-title>
        </v-card-header>
        <loader v-if="loading" class="my-5" />
        <template v-else>
          <template v-if="totalRows">
            <ledger-entries-index-table :rows="rows" />
            <pagination
              @change:page="index($event)"
              :total="meta.total"
              :current_page="meta.current_page"
              :from="meta.from"
              :to="meta.to"
              :next_page_url="links.next"
              :prev_page_url="links.prev"
            />
          </template>
          <no-results v-else class="m-5" />
        </template>
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import ledgerEntriesApi from "../api/resources/ledgerEntriesApi";
import accountsBalanceApi from "../api/resources/accountsBalanceApi";

import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import PageSection from "../components/ui/PageSection.vue";
import VCardHeader from "../components/ui/VCardHeader.vue";
import VCardTitle from "../components/ui/VCardTitle.vue";
import LedgerEntriesIndexTable from "../components/LedgerEntriesIndexTable.vue";

export default {
  components: {
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    PageSection,
    VCardHeader,
    VCardTitle,
    LedgerEntriesIndexTable,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      houseBalance: 0,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;
      ledgerEntriesApi
        .index(page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
          this.balance();
        })
        .catch((error) => console.log(error));
    },

    balance() {
      this.loading = true;
      accountsBalanceApi
        .index(1)
        .then((response) => {
          this.houseBalance = response.data;
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  created() {
    this.index();
  },
};
</script>

<style></style>
