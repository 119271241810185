<template>
  <app-layout>
    <template #header> Adauga comision </template>
    <page-section>
      <vendors-show-tabs current="vendors.fees.index" :id="id" />
    </page-section>
    <page-section>
      <vendors-fees-create-form
        class="mb-6"
        :vendor-id="id"
        @fee:created="redirect()"
      />
    </page-section>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import VendorsFeesCreateForm from "../components/VendorsFeesCreateForm.vue";
import PageSection from "../components/ui/PageSection.vue";
import VendorsShowTabs from '../components/VendorsShowTabs.vue';

export default {
  components: {
    AppLayout,
    VendorsFeesCreateForm,
    PageSection,
    VendorsShowTabs,
  },

  props: ["id"],

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    redirect() {
      this.$router.push({
        name: "vendors.fees.index",
        params: { id: this.id },
      });
    },
  },
};
</script>

<style></style>
